@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

html {
  background: #191d1f;
  color: #4e57a0;
  color: #5863bd;
  font-family: 'Roboto', sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 15% 2% 73%;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
}

/* header section start */
.header {
  grid-row: 1/2;
  grid-column: 1/2;
  display: -webkit-flex;
  display: flex;
}
.header > * {
  -webkit-flex: 1 1;
          flex: 1 1;
}

/* header section end */

/* search section begins */
.search-container {
  grid-row: 2/3;
  grid-column: 1/2;
  display: -webkit-flex;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
.auto-complete {
  width: 600px;
  color: #dc143c;
}

.search-btn {
  width: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.search > * {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
}


/* search section ends */

/* show stock begins */
.showStock{
grid-row: 3/4;
padding-left: 120px
}
/* show stock ends */

/* graph section begins */
.graph {
  display: -webkit-flex;
  display: flex;
  grid-row: 4/5;
  grid-column: 1/2;
  width: 100%;
  height: 95%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.tooltip {
  border-radius: 0.25rem;
  background: #131518;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}

